import * as React from 'react'
import PrimaryButton from 'src/components/UI/Button/PrimaryButton'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'

const NotFound = () => (
  <div className="container notFound">
    <h1>404</h1>
    <p>Page could not be found.</p>
    <LinkComponent to={'/'}>
      <PrimaryButton>Go to startpage</PrimaryButton>
    </LinkComponent>
  </div>
)

export default NotFound
